import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  QuickFacts,
  BannerLinks,
  BannerSmall,
  CallUs,
  ProjectPagination,
  Image,
} from '../../components'

import boxes from '../../components/BannerLinks/DefaultBox'
import contacts from '../../components/CallUs/DefaultContacts'

import { generateBreadcrumbsLinks } from '../../utils/index'

import HoverButton from '../../components/HoverButton'
import BannerImage from '../../assets/header.stadtamthall.jpg'
import image4 from '../../assets/bannerlinks/bannerbox.tq.jpg'
import image5 from '../../assets/bannerlinks/bannerbox.tiwag.jpg'
import Anny from '../../assets/anny.franzelin.jpg'

export const frontmatter = {
  title: 'Stadtamt Hall',
  slug: '/Projekte/Stadtamt Hall'
}

const Dioezese = () => {
  const links = generateBreadcrumbsLinks('/projekte/stadtamthall', 'Stadtamt Hall')
  return (
    <Layout margin={false} color="white">
    <Helmet
      title="Stadtamt Hall Relaunch"
      meta={[
        {
          name: 'description',
          content:
            'Vorhang auf für das Stadtamt Hall in Tirol! Die neu gestaltete Website überzeugt mit einem aufgeräumten, aber sehr lebendigen Design, einer übersichtlichen Navigation zu allen Bürgerthemen und vielen Details sowie zahlreichen Informationen. Wenn Sie Hall noch nicht lieben, könnte Ihnen diese Seite dabei helfen ❤'
        },
        {
          name: 'keywords',
          content:
            'Stadtamt Hall holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, Stadtamt Hall, stadtamt, Hall, Hall in Tirol'
        },
        {
          property: 'og:description',
          content:
            'Vorhang auf für das Stadtamt Hall in Tirol! Die neu gestaltete Website überzeugt mit einem aufgeräumten, aber sehr lebendigen Design, einer übersichtlichen Navigation zu allen Bürgerthemen und vielen Details sowie zahlreichen Informationen. Wenn Sie Hall noch nicht lieben, könnte Ihnen diese Seite dabei helfen ❤'
        },
        {
          property: 'og:image',
          content: 'https://www.holzweg.com/static/header.stadtamthall-3e86c3651916a19d1a7084ea112c676d.jpg'
        }
      ]}
    />
     <BannerSmall image={BannerImage} title="Relaunch Stadtamt Hall in Tirol" />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <>
            <div>
            Relaunch Stadtamt Hall in Tirol
            </div>
            <HoverButton buttonStyle="" dataText="Hall in Tirol" dataTitle="Dive In!" link={"https://www.hall-in-tirol.at/"} />
          </>
        }
        text={
          <span>
            <p>
            In enger Kooperation mit der Stadt Hall in Tirol übernahmen wir die Gestaltung ihrer Website. Gemeinsam erarbeiteten wir in Strategieworkshops Anforderungen mittels bewährter Beispiele, um eine klare Basis für den Webauftritt zu schaffen. Unsere Rolle erstreckte sich über die digitale Umsetzung hinaus: Wir gestalteten den Datenerhebungsprozess neu und füllten die Website nach umfassender Schulung. Besonders effizient verlief die Kommunikation über unser Ticketsystem für Fehlermeldungen und Anmerkungen. Die Stadt spiegelt sich nun anhand von Lebensbereichen und Bürgeranliegen wider, dank einer fruchtbaren Kooperation. Unsere partnerschaftliche Beziehung bleibt ein Motor für die Entwicklung von www.hall-in-tirol.at.
            </p>
          </span>
        }
      />
      <TextBox
        title={
          <div>
            Zufriedene<br /> Kundenstimme:
          </div>
        }
        text={
          <span>
            <i>
              <p>"Das Vorhaben, für eine Stadt eine neue Website zu erstellen ist schnell gefasst, aber aufgrund der Komplexität der städtischen Verwaltung in der Umsetzung nicht immer ganz einfach. Die Firma Holzweg hat den Prozess für die neue Website von Hall in Tirol von Anfang an aktiv begleitet: Schon vor der Erstellung eines konkreten Angebotes wurden in diversen Strategieworkshops mit Einbindung aller Stakeholder gemeinsam die Anforderungen an eine neue Website erarbeitet. Anhand von sorgsam ausgewählten Best Practice Beispielen konnte so ein Weg zu einer gemeinsamen „Sprache“ zwischen IT-Laien und IT-Profis gefunden werden, so dass die konkreten Anforderungen an den Webauftritt für alle klar erarbeitet werden konnten. Eine der wichtigsten Anforderungen war es, die Stadt nicht nach ihren Abteilungen abzubilden, sondern nach Lebensbereichen und Fragen der BürgerInnen.</p>
              <p>Das Team von Holzweg hat sich nicht nur um die digitale Umsetzung der Website gekümmert, sondern auch darum, wie die Vorgehensweise der Datenerhebung in den einzelnen Ämtern vonstattengehen muss. Nach diesem sehr langen und umfangreichen Prozess, konnte es daran gehen, die Website zu befüllen. Im Vorfeld gab es dazu eine ausführliche persönliche Einschulung durch das Holzweg-Team.</p>
              <p>Besonders hervorheben möchte ich die reibungslose Kommunikation ab diesem Zeitpunkt über ein Ticketsystem: Sei es die Meldung von Bugs, Änderungswünschen oder anderen Anmerkungen zur entstehenden Website, sie konnten alle über dieses Ticketsystem an das Programmiererteam übermittelt werden. Zu jedem Zeitpunkt ist für alle Projektpartner sichtbar, wer an dem Problem arbeitet und welchen Status es gerade hat. Keine Informationen gehen verloren und können auch nach Problembehebung später noch abgerufen werden.
              </p>
              <p>Die Erstellung der neuen Website von Hall in Tirol ist ein sehr gutes Beispiel, dass Websiten-Programmierung schon viel früher im Prozess beginnen muss. Nur wenn man schon von Anfang an zusammenarbeitet, kann das Ergebnis danach für alle zur Zufriedenheit führen. Die Zusammenarbeit mit den verschiedenen Teammitgliedern der Firma Holzweg war zu jedem Projektzeitpunkt sehr angenehm und konstruktiv. Der firmeninterne Informationsfluss funktioniert ausgezeichnet, auch wenn das Projekt dann von der Projektierungs- in die Umsetzungsphase kommt und sich die Ansprechpartner ändern.</p>
              <p>Wir freuen uns auf eine weiterhin gute Zusammenarbeit mit vielen technischen Inputs zur Weiterentwicklung unserer Website <a href="https://www.hall-in-tirol.at" target="_blank">www.hall-in-tirol.at</a>."</p>
            </i>
          </span>
        }
      />
      <Image
        image={Anny}
        title=""
      />
      <TextBox title="" text={
        <span><p><b>Mag. Anny Franzelin M.A.</b><br />Stadtmarketing Hall in Tirol – Projektleitung Website der Stadt Hall in Tirol</p>
        </span>
      }
      />
      <CallUs contacts={contacts} title="Gerne für Sie da" />
      <TextBox titleMods={["center"]}
        title="Weitere Projekte"
        text={false}
      />
      <ProjectPagination
        titleLeft="Relaunch TQ-Group"
        imageLeft={image4}
        leftLink="/projekte/relaunch-tq-group"
        titleRight={
          <span>Tiwag Wärmepumpen<br/>Animationen</span>
        }
        imageRight={image5}
        rightLink="/projekte/tiwag-waermepumpe"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Dioezese
